import React from "react";

function Form() {
  return (
    <>
      <main className="flex min-w-screen min-h-screen bg-slate-200">
        <div className="flex w-full grow justify-center items-center">
          <div className="flex flex-col w-full max-w-sm bg-white p-9 rounded-xl shadow-xl">
            <div className="flex flex-row">
              <p className="text-sm font-medium mb-3 mt-1">
                <span style={{color: "rgb(156 163 175)"}}>Endevre ID</span>
              </p>
            </div>
            <h1 className="text-3xl text-zinc-950 font-bold tracking-normal mb-3">
              Enter your authentication code
            </h1>
            <h2 className="text-sm text-black font-normal tracking-normal mb-10">
              Verify it's you by entering the 6-digit code from your authentication app.
            </h2>
            <form className="flex flex-col justify-start items-start">
              {/* One big text box */}
              <div className="flex flex-col w-full mb-5">
                <label>
                  <span className="text-sm text-black font-medium">6-digit code</span>
                </label>
                <input type="text" maxLength={6} placeholder="- - - - - -" className="text-lg bg-slate-200 hover:bg-slate-300 focus:bg-slate-300 outline-none w-full px-3 py-2 my-1 rounded-lg transition-colors duration-150 ease-in-out" /> 
              </div>              
              <div className="flex items-center mb-8">
                  <input id="link-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
                  <label htmlFor="link-checkbox" className="ml-2 text-sm font-small text-black">Remember this browser</label>
              </div>

              <div className="flex flex-col w-full mb-8">
                <button className="centered-button grow-0 px-3 py-1 bg-blue-500 hover:bg-blue-400 rounded-lg text-lg text-white font-medium transition-colors duration-150 ease-in-out">
                  Verify
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  );
}

export default Form;
