import React from "react";

function App() {
  return (
    <>
      <main className="flex min-w-screen min-h-screen bg-white">
        <div className="flex w-fulll grow justify-center items-center">
          <h1 className="text-4xl text-black font-semibold tracking-tight">
            Hello World!
            <p>click <span style={{color: "blue"}}> <a href="/Form">here</a> </span> to sign in.</p>
          </h1>
        </div>
      </main>
    </>
  );
}

export default App;
